import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { useAppContext } from '../../AppContextProvider';
import ProdcutCard from './ProdcutCard';
import { ProductPopup } from './ProductPopup';

export const SelectedCatogary = ({ setShowModal }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const products = location?.state?.products;
    const procatagory = location?.state?.procatagory;
    const [showPopup, setShowPopup] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const { selectedCategorytype, setSelectedCategorytype } = useAppContext()
    const [showSlecStore, setShowSlecStore] = useState(false)
    const [searchQuery, setSearchQuery] = useState('');
    const displayedProducts = searchQuery ? products?.filter(item => { return item?.name.toLowerCase().includes(searchQuery.toLowerCase()); }) : products;
    console.log(selectedCategorytype, "==2");
    const { storeproduct, setstoreproduct } = useAppContext()

    const [ageModal, setAgeModal] = useState(false)
    const handleCategorytypeChange = (category) => {
        // console.log(category, "category");
        // setSelectedCatPop(true)
        setSelectedCategorytype(category);
        
        navigate('/selectedCategory')
        // setSelectedCategory(category);
    };
    const handleSearchInputChange = (event) => {
        setSearchQuery(event.target.value);
    };
    const HandelClosePopup = (e, product) => {
        setShowPopup(e)
        setSelectedProduct(product);
    };
    const handelClose = (e) => {
        // setShowDropdown(true);
        setShowSlecStore(false)
        navigate('/saveLocation')
    }

    return (
        <div className='w-full  items-center flex  justify-center bg-white'>
            <div className=' w-full   items-center justify-center flex flex-col '>
                <div className='flex flex-col justify-center gap-4 w-full items-center bg-[#2A2F3D] py-6'>
                    <div className='flex justify-center gap-4 items- bg-[#2A2F3D] py-0 w-full  overflow-x-auto md:space-x-4 sm:space-x-2  sm: scrollbar-thin '>
                        {procatagory && procatagory?.map((item, cindex) => (
                            <div onClick={() => setSelectedCategorytype(item?.name)} key={cindex} className=' cursor-pointer  items-center w-15 md:w-20  flex flex-col'>
                                <div className='bg-white  rounded-full  border items-center justify-center flex flex-col'>
                                    <img height="180"
                                        width="230"
                                        className="rounded transition-transform duration-500 rounded-full"
                                        src={`https://api.vapedelivery.com/${item?.icon}`}
                                        alt=""
                                    /></div>
                                <p className='text-sm py-2 text-white font-semibold text-center'> {(item?.name)}</p>
                            </div>
                        ))}
                    </div>
                    <div className="flex w-full flex-wrap  justify-center p-4 mb-3">
                        <input className='md:w-w/5 w-3/4 px-3 py-3 mb-8 border-2 focus:border-orange-500 border-gray-200  rounded-full focus:outline-none transition-colors'
                            type="text" placeholder="Search By Name..." value={searchQuery} onChange={handleSearchInputChange} />
                    </div>
                </div>

                <div style={{ margin: "-40px 0px 0px 0px" }} className='bg-[#F4F2EE] md:w-4/5 w-full m-auto self-center rounded-2xl py-2 px-0 md:px-6 xl:px-8 m-[-44px]' >
                    {/* procatagory */}

                    {selectedCategorytype &&
                        <div className={" w-full"} >
                            <div className='flex justify- w-full xl:w-[22%] cursor-pointer '>
                                <h3 onClick={() => navigate('/product',)}
                                    className="flex text-sm items-center my-4 mx-2">Home
                                    <svg className='ml-2' width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg"                                        >
                                        <path d="M2.35742 2.33203L8.35742 8.33203L2.35742 14.332" stroke="#2A2F3D" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </h3>
                                <h1 className="font-bold xl:text-xl text-sm text-starts my-4">{(selectedCategorytype)}</h1>
                            </div>
                            <h1 className="font-bold xl:text-4xl text-xl text-start mb-4"> {(selectedCategorytype)}</h1>
                            <div className="w-full flex flex-wrap justify-between md:gap-3 gap-0.5  overflow-auto  scrollbar-hide scrollbar-thin ">
                                {displayedProducts.filter(x => x.category?.name === selectedCategorytype).map((product, index) => (<ProdcutCard product={product} HandelClosePopup={HandelClosePopup} />))}
                            </div>
                        </div>}



                </div>
                {showPopup && <ProductPopup setShowPopup={setShowPopup} setShowModal={setShowModal} selectedProduct={selectedProduct} />}
            </div >
            {/* SHow stor avalibility popup */}
            {showSlecStore && (
                <>
                    {storeproduct.name ?
                        <div className="fixed  flex justify- items-center bottom-[3%] left-[30%] bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded" role="alert">
                            <strong className="font-bold pr-2">Sorry. </strong>
                            <span className="block sm:inline"> We don't deliver in your area yet, please select a different address. </span>
                            <span onClick={() => { setShowSlecStore(false) }} className=" top-0 bottom-0 right-0 px-4 py-3">
                                <svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                    <title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                                </svg>
                            </span>
                        </div>
                        :
                        <div className="fixed top-0 scrollbar overflow-y-auto right-0 h-full top-0 z-20 left-0 w-full h-full bg-gray-800 bg-opacity-50 flex items-center justify-center">
                            <div className="relative bg-white rounded-lg shadow xl:w-1/3">
                                <svg onClick={() => { setShowSlecStore(false) }} className='absolute top-3 end-2.5 cursor-pointer rounded-full hover:shadow-md' width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="16.3242" cy="16.0781" r="16" fill="#FF4141" />
                                    <path d="M21.6654 10.7349C21.335 10.4045 20.7994 10.4045 20.469 10.7349L16.3242 14.8797L12.1793 10.7349C11.8489 10.4045 11.3133 10.4045 10.9829 10.7349C10.6526 11.0653 10.6526 11.6009 10.9829 11.9313L15.1278 16.0761L10.9829 20.221C10.6526 20.5513 10.6526 21.087 10.9829 21.4173C11.3133 21.7477 11.8489 21.7477 12.1793 21.4173L16.3242 17.2725L20.469 21.4173C20.7994 21.7477 21.335 21.7477 21.6654 21.4173C21.9957 21.087 21.9957 20.5513 21.6654 20.221L17.5205 16.0761L21.6654 11.9313C21.9957 11.6009 21.9957 11.0653 21.6654 10.7349Z" fill="white" />
                                </svg>
                                <div className="p-4 md:p-5 text-center ">
                                    <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                        <path stroke="red" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                    </svg>
                                    <h3 className={" text-xl text-center mb-3 leading-6 font-bold text-gray-700"} id="modal-headline" >  Check if it's available near you  </h3>
                                    <h3 className="mb-5  text-lg font-normal text-gray-700 ">Please provide a delivery address to check if this product is available.</h3>
                                    <button onClick={() => { handelClose() }} data-modal-hide="popup-modal" type="button" className="text-white w-32 bg-orange-600 hover:bg-orange-800 focus:ring-0 focus:outline-none focus:ring-orange-300 dark:focus:ring-orange-600 font-medium rounded-full text-sm inline-flex items-center px-5 py-2.5 text-center">Add Address</button>
                                </div>
                            </div>
                        </div>
                    }</>
            )}

        </div >
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(SelectedCatogary)