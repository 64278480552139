import React, { Component, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppContext } from '../AppContextProvider';
import Swal from 'sweetalert2';
import CardImages from '../Assets/card.png'
import cardtype from '../Assets/cardtypeIcon.png'
import cardgif from '../Assets/cardd.gif'
import cardpic from '../Assets/credit-card.png'
import cashgif from '../Assets/cash.gif'
import boxgif from '../Assets/box.png'
import { RotatingLines } from 'react-loader-spinner'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios';
const Payment = ({ payment, data, setshowPaymentMod, total }, props) => {

  const [formData, setFormData] = useState(null);
  const [loader, setLoader] = useState(false);
  const { value, setValue } = useAppContext();
  const [isFocused, setIsFocused] = useState(false);
  const [verfied, setVerifed] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(0);

  let navigate = useNavigate();
  useEffect(() => {
    setFormData(data)
    console.log(data)

  })

  useEffect(() => {

    if (paymentMethod === 1) {
      const elements = window.clover.elements();
      const displayError = document.getElementById('card-errors');

      const cardNumberElement = elements.create('CARD_NUMBER', styles);
      const cardDateElement = elements.create('CARD_DATE', styles);
      const cardCvvElement = elements.create('CARD_CVV', styles);
      const cardPostalCodeElement = elements.create('CARD_POSTAL_CODE', styles);

      cardNumberElement.mount('#card-number');
      cardDateElement.mount('#card-date');
      cardCvvElement.mount('#card-cvv');
      cardPostalCodeElement.mount('#card-postal-code');

      cardNumberElement.addEventListener('change', (event) => {
        displayError.textContent = event.error ? event.error.message : '';
      });
      cardDateElement.addEventListener('change', (event) => {
        displayError.textContent = event.error ? event.error.message : '';
      });
      cardCvvElement.addEventListener('change', (event) => {
        displayError.textContent = event.error ? event.error.message : '';
      });

      cardPostalCodeElement.addEventListener('change', (event) => {

        displayError.textContent = event.error ? event.error.message : '';
      });

      const cloverFooter = document.getElementsByClassName('clover-footer')[0];
      if (cloverFooter) {
        cloverFooter.style.display = "none";
      }
      return () => {
        cloverFooter && cloverFooter.parentNode.removeChild(cloverFooter);
      };
    }




  }, [paymentMethod]);



  const callCreateChargeAPI = async (response) => {

    const displayError = document.getElementById('card-errors');
    if (!data) {
      displayError.textContent = 'Item not added to cart added again';
      return;
    } else {
      // displayError.textContent = result.token;
      const updatedData = {
        ...formData,
        source: response.token,
        customer: localStorage.userid
      };
      try {
        const chargeResponse = await fetch('https://api.vapedelivery.com/api/main/createCharge'
          , {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedData),
            mode: 'cors',
          });
        const serverdata = await chargeResponse.json();
        setLoader(false);

        if (chargeResponse.status === 201) {
          // Swal.fire({
          //   icon: 'success',
          //   title: data.message,
          //   showConfirmButton: true,
          // });
          setValue([])

          navigate('/paymentSucess', { state: { payment: payment } });
        } else {

          Swal.fire({
            icon: 'warning',
            title: serverdata.error,
            showConfirmButton: true,
          });
        }
        // props.outputHandler(`Payment Success, Confirmation # is - ${chargeResponse.data.id}`);
      } catch (error) {
        setLoader(false);
        // console.error('Error:', error);
        Swal.fire({
          icon: 'warning',
          title: 'An error occurred. Please try again.',
          showConfirmButton: true,
        });
      }
      // displayError.textContent = chargeResponse.data;
      // return chargeResponse.data;
    }
  };


  const onChangeCAP = (event) => {
    setVerifed(true);
  }
  const handleFormSubmit = async (event) => {
    event.preventDefault();



    const displayError = document.getElementById('card-errors');
    setLoader(true)
    // console.log(formData, "----");


    try {
      const result = await window.clover.createToken();

      if (result.errors) {
        setLoader(false)

        Object.values(result.errors).forEach(value => {
          //  displayError.textContent = value;
          Swal.fire({
            icon: 'warning',
            title: `Car`,
            showConfirmButton: true,
          });
        });
      } else {
        await callCreateChargeAPI(result);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const cashhandler1 = async () => {
    const updatedData = {
      ...formData,
      customer: localStorage.userid
    };
    try {

      const response = await axios.get('https://api.vapedelivery.com/api/main/place-order', {
        withCredentials: false,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedData),
        mode: 'cors',
      });
      const serverdata = await response.json();
      console.log(serverdata, "serverdata");


    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const cashhandler = async (response) => {
    const updatedData = {
      ...formData,
      customer: localStorage.userid,
    };
    if (updatedData.source) {
      delete updatedData.source;
    }

    try {
      const chargeResponse = await fetch('https://api.vapedelivery.com/api/main/place-order'
        , {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(updatedData),
          mode: 'cors',
        });
      const serverdata = await chargeResponse.json();
      setLoader(false);
      if (chargeResponse.status === 201) {
        setValue([])
        navigate('/paymentSucess', { state: { payment: payment } });
      } else {
        Swal.fire({
          icon: 'warning',
          title: serverdata.error,
          showConfirmButton: true,
        });
      }
    } catch (error) {
      setLoader(false);
      Swal.fire({
        icon: 'warning',
        title: 'An error occurred. Please try again.',
        showConfirmButton: true,
      });
    }
  };
  const styles = {
    body: {
      fontFamily: 'Roboto, Open Sans, sans-serif',
      fontSize: '16px',
    },
    iframe: {
      height: '40px'
    },
    hydrated: {

    },
    input: {
      fontSize: '13px',
      border: '1px solid #aaa',
      height: '50px',
      borderRadius: '50px',
      padding: '8px'
    },
    img: {
      right: '10px !important',
      top: '10px !important',
    }
  };
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);

  return (
    <div className='flex w-full text-start max-w-screen-xl mx-auto justify-around items-start h-full my-4  '>
      <div className='md:flex w-full xl:w-2/3 justify-between  p-2'>
        <div className=" w-full md:w-[55%] rounded p-4   w-full md:h-[90%] bg-white" >
          <div id="card-errors" role="alert" />
          <p className='font-bold  text-xl text-gray-600 '>Select Payment method</p>
          <div className='flex w-3/4 gap-x-3 py-3 mb-3'>
            <button

              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              onClick={() => setPaymentMethod(1)}
              className={`block w-1/2 ${paymentMethod == 1 ? 'bg-white border-orange-500' : "bg-[#EAE9E9]"}  shadow  border focus: rounded rounded-xl px-3 md:py-3 py-1 font-semibold `}>
              <img className='md:h-[71px] h-[40px]' src={isHovered ? cardgif : cardpic} alt="Cash GIF" />
              <label className="text-[9px] md:text-[16px] xl:text-[16px] text-gray-600">Credit/ Debit</label>
            </button>
            <button
              onMouseEnter={() => setIsHovered2(true)}
              onMouseLeave={() => setIsHovered2(false)}
              onClick={() => setPaymentMethod(2)}
              className={`block w-1/2  bg-[#EAE9E9] shadow ${paymentMethod == 2 ? 'bg-white border-orange-500' : "bg-[#EAE9E9]"} border  rounded rounded-xl px-3  md:py-3 py-1 font-semibold `}            >
              <img className={`${isHovered2 ? 'md:h-[71px] h-[40px]' : 'md:h-[70px] h-[40px]'}`} src={isHovered2 ? cashgif : boxgif} alt="Cash GIF" />
              <label className="text-start text-gray-600 text-[9px] md:text-[16px] ">Cash on Delivery</label>
            </button>
          </div>
          {paymentMethod > 0 && <div className=" justify-center shadow border px-4 p-3 rounded rounded-xl ">
            {paymentMethod === 1 && <>
              <img className='md:h-[40px] h-[25px] self-center' src={cardtype} alt="Cash GIF"  />
              <form id="payment-form" noValidate autoComplete="on" >
                <fieldset className="FormGroup pt-4">
                  <div className="FormRow ">
                  </div>
                  <div className="FormRow w-full flex flex-col justify-center items-center ">
                    <div style={{ height: '60px', width: '96%' }} id="card-number" className="field card-number rounded-full mb-2" />
                    <div style={{ height: '60px', width: '96%' }} id="card-date" className="field third-width mb-2" />
                    <div style={{ height: '60px', width: '96%' }} id="card-cvv" className="field third-width mb-2" />
                    <div style={{ height: '60px', width: '96%' }} id="card-postal-code" className="field third-width mb-2" />
                  </div>
                </fieldset>
              </form>
            </>}
            {paymentMethod === 2 && <div className=' justify-between w-full gap-x-3 py-2'>
              <p className='text-start text-[10px] text-gray-500 py-2 font-medium'>You may pay in cash to our courier upon receiving your parcel at the doorste</p>
              <p className='text-start text-[10px] text-gray-500 py-2 font-medium'>Before agreeing to receive the parcel, check if your delivery status has been updated to 'Out for Delivery'</p>

              <p className='text-start text-[10px] text-gray-500 py-2 font-medium'>
                Cash on Delivery is free. There is no extra fee when using other payment methods</p>
            </div>}
            <div className='flex w-full flex-col justify-center  gap-2'>
              <RotatingLines
                visible={loader}
                height="40"
                width="40"
                color="orange"
                strokeColor='orange'
                strokeWidth="5"
                animationDuration="0.75"
                ariaLabel="rotating-lines-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />

              <div className="w-full " style={{ transform: 'scale(0.8)', transformOrigin: '0 0' }}>
                <ReCAPTCHA
                  sitekey="6LdrXCMqAAAAAP0zXpxCH0WuU4W4Ed7YGImclnPr"
                  onChange={onChangeCAP}
                />
              </div>
              <div className='flex justify-around gap-2'>
                {paymentMethod === 1 && (!loader) && verfied && <button disabled={!verfied} className={`block w-1/2 max-w-xs  bg-orange-500 hover:bg-orange-700 focus:bg-orange-700 text-white rounded-full px-6 py-2 font-semibold`} variant="contained" color="primary" size="large" onClick={handleFormSubmit}>
                  PAY NOW${total}
                </button>}
                {paymentMethod === 2 &&
                  (!loader) && verfied && <button disabled={!verfied} className={`block w-full max-w-xs  bg-orange-500 hover:bg-orange-700 focus:bg-orange-700 text-white rounded-full px-6 py-2 font-semibold`} variant="contained" color="primary" size="large" onClick={cashhandler}>
                    CONFIRM PURCHASE
                  </button>}
                <button

                  className='flex w-1/2 rounded-full cursor-pointer justify-center shadow  border border-gray-500 text-xs font-bold py-3 px-6 leading-none flex items-center text-center text-gray-500 hover:bg-gray-400 hover:text-white' type="button" variant="contained" size="large" onClick={() => setshowPaymentMod(true)}>
                  CANCEL
                </button>
              </div>
            </div >
          </div >}
        </div >
        <div className="w-full md:w-[40%] justify-center p-4 border bg-white mt-4 rounded rounded-xl w-full md:h-[90%] ">
          <p className='font-bold  text-xl text-gray-600 '>Order Summary</p>
          < div className='flex justify-between w-full gap-x-3 py-1'>
            <p className='text-start text-[10px] text-gray-500 py-2 font-medium'>Subtotal ( 1 item and shopping fee included)</p>
            <p className='text-start text-[10px] text-gray-500 py-2 font-medium'>{total}</p>
          </div>
          <div className='flex py-2 justify-beteen my-2 w-full'>
            <label className=' w-1/2 text-black'><b>Total:</b></label>
            <label className=" text-end w-1/2 text-orange-500"><b>${total}</b></label>
          </div>
        </div>
        {/* <img src={CardImages} alt="Card" width={400} height={300} /> */}

      </div>
    </div >
  );
}


const Checkout = ({ mapData, userData }) => {
  let navigate = useNavigate();
  const [showPaymentMod, setshowPaymentMod] = useState(true)
  const [taxpercentage, setTaxPercentage] = useState(true)
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const { storeproduct, setstoreproduct } = useAppContext()

  // console.log(storeproduct, "storeproduct");

  const location = useLocation();
  const { value, setValue } = useAppContext();
  const states = [

    { "name": "Pennsylvania", "tax": 6.0 },
    { "name": "Texas", "tax": 6.25 },
  ];

  const calculateTotal = () => {
    const subtotal = value.reduce((total, item) => total + (item.product.rprice * item.quantity), 0);
    // console.log(subtotal, "Subtotal")
    const tax = (subtotal / 100) * (storeproduct?.location?.tax || 0);
    // console.log(tax, "tax")

    const deliveryCharge = 4.99;

    let total;
    let discount = 0;
    let firstdiscount = 0;
    const isFirstTimeUser = userData?.orders?.length === 0;
    const isOrderAbove60 = subtotal > 50;


    if (isFirstTimeUser) {
      firstdiscount += 0.3 * (subtotal); // 50% discount for first-time users
      if (isOrderAbove60) {
        discount += 0.15 * (subtotal); // Additional 15% discount if order is above $60
      }
    } else if (isOrderAbove60) {
      discount += 0.15 * (subtotal + tax); // 15% discount if order is above $60 for non-first-time users
    }




    if (isFirstTimeUser || isOrderAbove60) {

      const dicountPrice = ((subtotal - (discount + firstdiscount)))

      total = dicountPrice + (deliveryCharge + tax);

    } else {
      total = (subtotal + tax) + deliveryCharge;
    }



    return {
      subtotal: subtotal.toFixed(2),
      tax: tax.toFixed(2),
      deliveryCharge: deliveryCharge.toFixed(2),
      ...(discount > 0 && { discount: discount.toFixed(2) }), // Add discount only if applicable
      ...(firstdiscount > 0 && { firstdiscount: firstdiscount.toFixed(2) }),
      total: total.toFixed(2)
    };
  };
  const totalDetails = calculateTotal(); // Calculate total, tax, and delivery charge once


  useEffect(() => {
    const { total, tax, deliveryCharge, discount } = calculateTotal();
    const products = value.map(item => ({
      productId: item.product._id,
      flavour: item.product.flavours,
      quantity: item.quantity,
    }));

    setFormData(prevFormData => ({
      ...prevFormData,
      cart: products,    // Update cart with the new products
      total: parseFloat(total),
      tax: parseFloat(tax),
      discount: discount ? parseFloat(discount) : 0,
      devcharges: parseFloat(deliveryCharge),
    }));


  }, [])

  const [formData, setFormData] = useState({
    // fullname: '',
    // email: '',
    // phone: '',
    // street: '',
    // city: '',
    // state: '',

    source: 'clv_1TSTSorzbVGx6uMAA5s2d2y1',
    cart: [], // Initialize as an empty array
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handlePhoneChange = (value) => {
    setFormData({
      ...formData,
      phone: value
    });
  };
  const handleStateChange = (e) => {
    const stateObj = JSON.parse(e.target.value);
    const { name, tax } = stateObj;
    const statname = "state"
    setTaxPercentage(tax)
    setFormData({
      ...formData,
      [statname]: name,
    });

  };

  const paynow = (cartItems) => {

    // if (formData.fullname) {
    //   document.getElementById("fullname").style.borderColor = "silver";
    // } else {
    //   // document.getElementById("fullname").style.borderColor = "red";
    // }

    // if (formData.email) {
    //   document.getElementById("email").style.borderColor = "silver";
    // } else {
    //   document.getElementById("email").style.borderColor = "red";
    // }

    // if (!formData.phone) {
    //   document.getElementById("phone").style.borderColor = "red";
    // } else {
    //   document.getElementById("phone").style.borderColor = "silver";
    // }
    // if (!formData.street) {
    //   document.getElementById("street").style.borderColor = "red";
    // } else {
    //   document.getElementById("street").style.borderColor = "silver";
    // }

    // if (!formData.state) {
    //   document.getElementById("state").style.borderColor = "red";
    // } else {
    //   document.getElementById("state").style.borderColor = "silver";
    // }
    // if (!formData.city) {
    //   document.getElementById("city").style.borderColor = "red";
    // } else {
    //   document.getElementById("city").style.borderColor = "silver";
    // }
    // if (formData.zipcode) {
    //   document.getElementById("zipcode").style.borderColor = "silver";
    // } else {
    //   document.getElementById("zipcode").style.borderColor = "red";
    // }


    const { total, tax, deliveryCharge, discount, firstdiscount } = calculateTotal();
    // Extract product data from cartItems array
    const products = value.map(item => ({
      productId: item.product._id,
      flavour: item.product.flavours,
      quantity: item.quantity,
    }));

    const business = storeproduct?.frmData?.business ? storeproduct.frmData.business + ', ' : '';
    const building = storeproduct?.frmData?.location ? storeproduct.frmData.location + ', ' : '';
    // Update formData state with new data
    setFormData(prevFormData => ({
      ...prevFormData,
      cart: products, // Update cart with the new products
      fullname: userData?.profile?.name || formData.fullname,
      phone: userData?.profile?.phone || formData.phone || "-",
      email: userData?.profile?.email || formData.email,
      street: business + '' + building + '' + storeproduct.location?.name,
      state: storeproduct.location?.state,
      city: storeproduct.location?.city || "-",
      zipcode: storeproduct.locatioin?.zip || "1234",
      tax: parseFloat(tax),
      devcharges: parseFloat(deliveryCharge),
      discount: discount ? parseFloat(discount) : 0,
      firstdiscount: firstdiscount ? parseFloat(firstdiscount) : 0,
      total: parseFloat(total),

    }));


    if (formData.fullname
      && formData.email
      // && formData.phone
      // && formData.street
      // && formData.state
      // && formData.city
      // && formData.zipcode
    ) {
      // Make sure cart is updated here

    }
    setshowPaymentMod(false)
    // Now you can send formData to your API
  };

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {

    if (storeproduct?.storeAddress && userData?.profile?.phone) {
      setIsChecked(!isChecked);
    }
  };
  const [isFocused, setIsFocused] = useState(false);
  const inputStyle = {
    width: '100%',
    // padding: '0.75rem',
    border: '1px solid #E5E7EB', // border-gray-200
    outline: 'none',
    paddingTop: '20px ',
    paddingBottom: '20px ',
    transition: 'border-color 0.2s ease-in-out' // Optional: smooth transition for border color change
  };
  const capitalizeName = (name) => {
    if (!name) return '';

    return name.split(' ').map(word => {
      return word.charAt(0).toUpperCase() + word?.slice(1).toLowerCase();
    }).join(' ');
  }
  // const formattedName = capitalizeName(userData?.profile?.name);

  const focusStyle = isFocused ? { borderColor: '#FB923C' } : {}; // focus:border-orange-500
  return (
    <div className="checkout w-full bg-white">

      {showPaymentMod ?
        <div className='flex justify-center items-center h-full '>
          <div className={`md:w-[80%] xl:flex text-gray-700 h-full inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl sm:my-8 sm:align-middle border border-1`}>

            <div className="xl:w-2/3  flex flex-col items-center justify- px-5 pb-10 pt-4 ">
              <div className="w-full  flex flex-col items justify-center xl:px-5 pb-10 pt-4">
                {userData.profile ?
                  <div className='w-full rounded-xl p-6 bg-gray-100  text-left'>
                    {/* <p className='text-gray-500 font-semibold  text-sm py-2'>User Details</p> */}
                    <p className=' text-2xl text-gray-600 font-bold text-md'>{capitalizeName(userData?.profile?.name)}</p>
                    <p className='  text-gray-600 font-medium text-md my-2'>Email: <label className='text-orange-500'>{userData?.profile?.email}</label></p>
                    <p className='  text-gray-600 font-medium text-md my-2'>phone:
                      <label className='text-orange-500'>{userData?.profile?.phone}</label>
                      {/* {
                    userData?.profile?.phone ? <label className='text-orange-500'>{userData?.profile?.phone}</label> : <>
                      <svg className="flex-shrink-0 inline w-5 h-5 ml-3 text-red-800" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                      </svg>
                      <label className='text-red-500 p-2 bg-red-100 rounded'>Kindly add Phone no to proceed</label>
                    </>} */}
                    </p>
                    <p className='  text-gray-600 font-medium text-md my-2'>Delivery Adress: <label className='text-orange-500'>{storeproduct?.frmData?.business ? storeproduct.frmData.business + ', ' : ''} {storeproduct?.frmData?.location ? storeproduct.frmData.location + ', ' : ''}{storeproduct?.location?.name || storeproduct?.name}</label></p>

                  </div>
                  :
                  <div className="w-full mx-auto rounded-lg bg-white shadow-lg p-5 " >
                    <h1 className="mb-3 font-bold text-lg uppercase">1) Personal Information</h1>
                    <div className='md:flex gap-2 w-full'>
                      <div className="mb-3 w-full">
                        <label htmlFor="fullname" className="font-bold text-sm mb-2 ml-1">Full Name*</label>
                        <input id="fullname" name="fullname" value={formData.fullname} onChange={(e) => handleChange(e)} className="w-full px-3 py-2 mb-1 border-2 border-gray-200 rounded-md focus:outline-none focus:border-orange-500 transition-colors" placeholder="John Smith" type="text" />
                      </div>

                    </div>
                    <div className='md:flex gap-2 w-full'>
                      <div className="mb-3 md:w-1/2">
                        <label htmlFor="email" className="font-bold text-sm mb-2 ml-1">Email address *</label>
                        <input id="email" name="email" value={formData.email} onChange={(e) => handleChange(e)} className="w-full px-3 py-2 mb-1 border-2 border-gray-200 rounded-md focus:outline-none focus:border-orange-500 transition-colors" placeholder="example@example.com" type="email" />
                      </div>
                      <div className="mb-3 md:w-1/2">
                        <label htmlFor="phone" className="font-bold text-sm mb-2 ml-1">Phone *</label>
                        {/* <input id="phone" name="phone" value={formData.phone} onChange={(e) => handleChange(e)} className="w-full px-3 py-2 mb-1 border-2 border-gray-200 rounded-md focus:outline-none focus:border-orange-500 transition-colors" placeholder="(555) 555-1234" type="number" /> */}

                        <PhoneInput
                          placeholder="Enter phone number"
                          country={'us'}
                          inputStyle={{ ...inputStyle, ...focusStyle }}
                          onlyCountries={['us']}
                          value={formData.phone}
                          onChange={handlePhoneChange}
                          className="w-full  border-2 border-gray-200 rounded-md focus:outline-none focus:border-orange-500 transition-colors"
                        /> </div>
                    </div>

                  </div>}
              </div>
              {/* Bill info */}
              {/* <div className="w-full  flex flex-col items-center justify-center px-5 pb-10 pt-4">
                <div className="w-full mx-auto rounded-lg bg-white shadow-lg p-5 " >

                  <h1 className=" mb-3 font-bold text-lg uppercase">2) shipping details</h1>

                

                  <div className='md:flex gap-2 w-full'>

                    <div className="mb-3 md:w-1/2">
                      <label htmlFor="street" className="font-bold text-sm mb-2 ml-1">Street</label>
                      <input id="street" name="street" value={formData.street || formData.street} onChange={(e) => handleChange(e)} className="w-full px-3 py-2 mb-1 border-2 border-gray-200 rounded-md focus:outline-none focus:border-orange-500 transition-colors" placeholder="Street" type="text" />
                    </div>
                    <div className="mb-3 md:w-1/2">
                      <label htmlFor="city" className="font-bold text-sm mb-2 ml-1">City *</label>
                      <input id="city" name="city" value={formData.city} onChange={(e) => handleChange(e)} className="w-full px-3 py-2 mb-1 border-2 border-gray-200 rounded-md focus:outline-none focus:border-orange-500 transition-colors" placeholder=" City" type="text" />
                    </div>
                  </div>
                  <div className='md:flex gap-2 w-full'>

                    <div className="mb-3 md:w-1/2">
                      <label htmlFor="countries" className="font-bold text-sm mb-2 ml-1">State *</label>
                      <select
                        onChange={handleStateChange}
                        id="state"
                        name="state"
                        className="form-select w-full px-3 py-2 mb-1 border-2 border-gray-200 rounded-md focus:outline-none focus:border-orange-500 transition-colors cursor-pointer"
                      >
                        <option value="" disabled selected>Select a state</option>
                        {states.map((state, index) => (
                          <option key={index} value={JSON.stringify(state)}>{state.name}</option>
                        ))}
                      </select>
                    </div>
                    <div className="mb-3 md:w-1/2">
                      <label htmlFor="zipcode" className="font-bold text-sm mb-2 ml-1">ZIP Code *</label>
                      <input id="zipcode" name="zipcode" value={formData.zipcode} onChange={(e) => handleChange(e)} className="w-full px-3 py-2 mb-1 border-2 border-gray-200 rounded-md focus:outline-none focus:border-orange-500 transition-colors" placeholder="00000" type="number" />
                    </div>
                  </div>
                </div>
              </div> */}

            </div>

            <div className={`xl:w-1/3 bg-white px-4   pb-4 sm:p-6 sm:pb-4 `}>
              <div className="mb-3">
                <h1 className=" font-bold text-md uppercase">Order Summary</h1>
              </div>


              {value?.map((item, index) =>
                <div key={index} className='  justify-beteen w-full border-[2px] p-3 mb-1 rounded'>
                  <div className='md:flex gap-4 '>
                    <div className='w- '>
                      <img className="w-20 h-20 rounded  " src={`https://api.vapedelivery.com/${item.product.featured}`} alt="" />
                      {/* <img className="w-28 h-28 rounded " src={`http://ec2-3-17-166-251.us-east-2.compute.amazonaws.com${item.product.featured}`} alt="" /> */}
                    </div>
                    <div className='md:w-2/3 pl-2'>
                      <div className='flex justify-between text-start w-full'>
                        <label className=" "><label className="text-md ">x({item.quantity}) </label><b>- {item.product.name}</b></label>

                        <label className=' text-orange-600 '><b>${(item.product.rprice * item.quantity).toFixed(2)}</b></label>

                      </div>
                      <p className=' pt-1 text-gray-400 text-sm font-semibold'>Flavour: <b>{item.product.flavours}</b></p>
                      <p className='pb-1 text-gray-400 text-sm font-bold'>Price: <b>${item.product.rprice.toFixed(2)}</b></p>
                    </div>
                  </div>
                </div>
              )}
              <div className='flex  justify-beteen my-2 w-full'>
                <label className=' w-1/2 text-gray-500'><b>SubTotal:</b></label>
                <label className=" text-end w-1/2 text-gray-500"><b>${totalDetails.subtotal}</b></label>
              </div>


              {userData?.orders?.length == 0 && <div className='flex justify-beteen my-2 w-full'>
                <label className=' w-2/3 text-red-500'><b>First Order Discount: 30%</b></label>
                <label className=" text-end w-1/3 text-red-500"><b>${totalDetails.firstdiscount}</b></label>
              </div>}
              {totalDetails.discount && (
                <div className='flex justify-between my-2 w-full'>
                  <label className='w-1/2 text-red-500'><b>Discount: 15%</b></label>
                  <label className="text-end w-1/2 text-red-500"><b> ${totalDetails.discount}</b></label>
                </div>
              )}

              <div className='flex  justify-beteen my-2 w-full'>
                <label className=' w-1/2 text-gray-500'><b>Tax:</b></label>
                <label className="text-end w-1/2 text-gray-500"><b>${totalDetails.tax}</b></label>
              </div>
              <div className='flex  justify-beteen my-2 w-full'>
                <label className=' w-1/2 text-gray-500'><b>Delivery:</b></label>
                <label className=" text-end w-1/2 text-gray-500"><b>${totalDetails.deliveryCharge}</b></label>
              </div>
              <div className='flex  border-b border-t  py-4 justify-beteen my-2 w-full'>
                <label className=' w-1/2 text-black'><b>Total:</b></label>
                <label className=" text-end w-1/2 text-black"><b>${totalDetails.total}</b></label>
              </div>
              <div className="my-4 flex items-center justify-beteen">
                <input type="checkbox" id="above18" checked={isChecked} onChange={handleCheckboxChange} />
                <label className=" px-2 font-semibold" htmlFor="above18">Are you above 21?</label>
              </div>
              <button onClick={() => paynow()} type='submit' disabled={!isChecked}
                className={`block w-full max-w-xs mx-auto bg-orange-500 hover:bg-orange-700 focus:bg-orange-700 text-white rounded-full px-3 py-3 font-semibold ${isChecked ? '' : 'opacity-50 cursor-not-allowed'}`}>
                <i className="mdi mdi-lock-outline mr-1"></i>Complete Purchase
              </button>
            </div>
          </div>

          {!storeproduct.storeAddress &&
            <div className="fixed pulse flex justify- items-center bottom-[3%] left-[30%] bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded" role="alert">
              <strong className="font-bold pr-2">Sorry. </strong>
              <span className="block sm:inline"> We don't deliver in your area yet, please select a different address. </span>
              <span
                // onClick={() => { setShowSlecStore(false) }}
                className=" top-0 bottom-0 right-0 px-4 py-3">
                <svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <title>Close</title>
                  <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                </svg>
              </span>
            </div>}
        </div >
        :
        <Payment data={formData} setshowPaymentMod={setshowPaymentMod} total={totalDetails.total} payment={totalDetails} />
      }
    </div >
  );
}

const styles = {
  body: {
    fontFamily: 'Roboto, Open Sans, sans-serif',
    fontSize: '16px',
  },
  iframe: {
    height: '40px'
  },
  hydrated: {

  },
  input: {
    fontSize: '13px',
    border: '1px solid #aaa',
    height: '50px',
    borderRadius: '10px',
    padding: '8px'
  },
  img: {
    right: '10px !important',
    top: '10px !important',
  }
};
export default Checkout;

