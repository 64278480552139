import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Navbar from './Pages/Navbar/Navbar';
import Product from './Pages/Products/Product';
// import ProductDescription from './Pages/Products/ProductDescription';
import Footer from './Pages/Footer/Footer';
import Home from './Pages/Home';
import Checkout from './Pages/Cheqout';
import ContactUS from './Pages/ContactUS';
import ProductDetails from './Pages/Products/ProductDetails';
import AboutUs from './Pages/AboutUs';
import Policy from './Pages/Policy';
import Returns from './Pages/Returns';
import { useAppContext } from './AppContextProvider';
import PaymentSucess from './Pages/PaymentSucess';
import Login from './Login';
import Signup from './Signup';
import UserProfile from './Pages/UserProfile';
// import Map from './Pages/map/Map';
import axios from 'axios';
import SaveLocation from './Pages/SaveLocation';
import SelectedCatogary from './Pages/Products/SelectedCatogary';
import TermsAndCondition from './Pages/TermsAndCondition';
// import Glog from './Pages/map/googlrlog';

// import { PayPalScriptProvider } from "@paypal/react-paypal-js";


function App({ cart, ...otherProps }) {
  const [ShowCheqoutModal, setShowModal] = useState(false);
  const { value, setValue } = useAppContext();
  const [userData, setUserData] = useState({});
  const { popHandel, setPopHandel } = useAppContext()

  useEffect(() => {
    const token = localStorage.getItem('token'); // Retrieve the token from localStorage
    if (token) {
      axios.get('https://api.vapedelivery.com/api/main/profile', {
        headers: {
          'Authorization': `Bearer ${token}` // Set the token in the Authorization header
        },
        withCredentials: false
      }).then(response => {
        setUserData(response.data);

      }).catch(error => {
        console.error('Error fetching data:', error);
      });
    } else {
      console.error('No token found in localStorage');
    }
  }, []);
  console.log(localStorage.token, "localStorage.token");

  const currentPath = window.location.pathname;
  const displayrec = otherProps.displayrec
  const displyselectedHandler = otherProps.displyselectedHandler
  const generateTitle = () => {
    switch (currentPath) {
      case '/':
        return 'Vape';
      case '/home':
        return ' Vape';
      case '/product':
        return 'Vape - Products';
      case '/permission':
        return 'Permission';
      case '/landing':
        return 'Landing';
      case '/Embrology':
        return 'embrology';
      case '/policy':
        return 'policy';
      case '/returns':
        return 'returns';
      case '/userProfile':
        return 'userProfile';
      case '/selectedCatogary':
        return 'selectedCatogary';
      default:
        return 'Vape';

    }
  }

  const initialOptions = {
    "client-id": "YOUR-CLIENT-ID-HERE",
    currency: "USD",
    intent: "capture",
  };


  return (
    <div className=" scrollbar relitive  scrollbar-thin  flex flex-col items-center justify-beteen bg-white w-full">
      {/* <PayPalScriptProvider options={initialOptions}> */}
      <BrowserRouter>
        <Helmet>
          <title>{generateTitle()}</title>
        </Helmet>
        <Navbar setShowModal={setShowModal} personData={userData} />
        {popHandel == 1 && popHandel !== 0 && < Login />}
        {popHandel == 2 && popHandel !== 0 && < Signup />}
        {popHandel == 3 && popHandel !== 0 && <ProductDetails />}
        {popHandel == 4 && popHandel !== 0 && <UserProfile userData={userData} />}

        {/* {ageModal && <PopupAge setAgeModal={setAgeModal} />} */}
        <Routes>
          <Route path="/" element={<Home setShowModal={setShowModal} ShowCheqoutModal={ShowCheqoutModal} />} />
          <Route path="/home" element={<Home userData={userData} displayrec={displayrec} displyselectedHandler={displyselectedHandler} setShowModal={setShowModal} ShowCheqoutModal={ShowCheqoutModal} />} />
          <Route path="/product" element={<Product userData={userData} setShowModal={setShowModal} ShowCheqoutModal={ShowCheqoutModal} displayrec={displayrec} displyselectedHandler={displyselectedHandler} />} />
          <Route path="/checkout" element={<Checkout userData={userData} />} />
          <Route path="/contactus" element={<ContactUS />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/saveLocation" element={<SaveLocation />} />
          <Route path="/policy" element={<Policy />} />
          <Route path="/terms" element={<TermsAndCondition />} />
          <Route path="/returns" element={<Returns />} />
          <Route path="/paymentSucess" element={<PaymentSucess />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/selectedCatogary" element={<SelectedCatogary setShowModal={setShowModal} />} />
          <Route path="/userProfile" element={localStorage.token && <UserProfile userData={userData} />}
          />
          {/* <Route path="/map" element={<Map />} /> */}
          {/* <Route path="/glog" element={<Glog />} /> */}
        </Routes>
        {value.length > 0 && < div onClick={() => setPopHandel(3)} >
          <div className=" flex fixed z-10 bottom-3 p-3 right-4 md:block bg-gray-200 shadow rounded-full">
            {/* <span className=" absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span> */}
            <svg className="icon icon-cart h-6 w-6 cursor-pointer  hover:text-orange-500" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M16 16C14.89 16 14 16.89 14 18C14 18.5304 14.2107 19.0391 14.5858 19.4142C14.9609 19.7893 15.4696 20 16 20C16.5304 20 17.0391 19.7893 17.4142 19.4142C17.7893 19.0391 18 18.5304 18 18C18 17.4696 17.7893 16.9609 17.4142 16.5858C17.0391 16.2107 16.5304 16 16 16ZM0 0V2H2L5.6 9.59L4.24 12.04C4.09 12.32 4 12.65 4 13C4 13.5304 4.21071 14.0391 4.58579 14.4142C4.96086 14.7893 5.46957 15 6 15H18V13H6.42C6.3537 13 6.29011 12.9737 6.24322 12.9268C6.19634 12.8799 6.17 12.8163 6.17 12.75C6.17 12.7 6.18 12.66 6.2 12.63L7.1 11H14.55C15.3 11 15.96 10.58 16.3 9.97L19.88 3.5C19.95 3.34 20 3.17 20 3C20 2.73478 19.8946 2.48043 19.7071 2.29289C19.5196 2.10536 19.2652 2 19 2H4.21L3.27 0M6 16C4.89 16 4 16.89 4 18C4 18.5304 4.21071 19.0391 4.58579 19.4142C4.96086 19.7893 5.46957 20 6 20C6.53043 20 7.03914 19.7893 7.41421 19.4142C7.78929 19.0391 8 18.5304 8 18C8 17.4696 7.78929 16.9609 7.41421 16.5858C7.03914 16.2107 6.53043 16 6 16Z" fill="currentColor" />
            </svg>
            <span className="absolute top-0 right-0 flex p-2.5 justify-center items-center rounded-full h-4 w-4 bg-orange-500 text-sm  text-white ">{value.length}</span>
          </div>
        </div>}


        {/* {popHandel == 3 && popHandel !== 0 && <UserProfile userData={userData} />} */}

        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
